.error-msg {
  font-size: 12px;
  color: red;
}
.custom_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  gap: 8px;
}

.search_con {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.search_con .search_input {
  /* padding-right: 15px; */
}

.search_con svg {
  position: absolute;
  right: 0;
  bottom: 17%;
  font-size: 18px;
}

.button-outlined {
  color: "#fff" !important;
}

header.MuiPaper-root {
  background: #3c90bd;
  box-shadow: 0px -12px 39px #3c90bd;
}
.css-1f4sdrv-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root, 
.css-14zcgjf-MuiButtonBase-root-MuiButton-root,
.css-tqmoez-MuiSwitch-thumb,
.css-1tihzpi-MuiButtonBase-root-MuiButton-root,
.css-1ejw9jv-MuiButtonBase-root-MuiButton-root{
  background-color: #3c90bd
}
.css-f2kk7z-MuiButtonBase-root-MuiIconButton-root{
  color: white;
}
.css-1pqm26d-MuiAvatar-img{
  object-fit:contain; 
  background: white;
}
.css-13iiatm-MuiListItemIcon-root{
  color: #3c90bd;
}
.css-do3vh5-MuiButtonBase-root-MuiIconButton-root{
  color: #3c90bd;
}
.css-vluhx3-MuiPaper-root-MuiCard-root{
  margin-top: 28px;
}


.upload-box {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border: 1px solid #e5e5e5;
  border-radius: 62px;
  padding: 20px; /* Adjust padding as needed */
  height: 120px; /* Set a specific height for the box */
  width: 120px; /* Set a specific width for the box */
  cursor: pointer; /* Change cursor to pointer for better UX */
  position: relative; /* Ensure positioning for better alignment */
  margin: 0 auto; /* Center the upload box itself in the container */
}

.avatar {
  height: 100px;
  position: relative;
  width: 100px;
}

/* .css-vluhx3-MuiPaper-root-MuiCard-root{
  margin-left: 33px;
    margin-right: 36px;
} */


.css-17pmaar-MuiCardContent-root {
  padding: 57px;
}
.fvMjqs{
  font-size: 18px !important;
}
.kUMlDB{
  font-size: 16px !important;
}
.leUHeK{
  justify-content: flex-start !important;
}

.error-msg {
  color: #d32f2f; 
  font-size: 14px; 
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.jodit-workplace ul , .customstyle ul{
  padding: revert !important; 
  margin: revert !important;
  list-style: revert !important;
}

img.MuiAvatar-img {
  object-fit: scale-down !important;
  background: white !important;
}


.arrowDropdown {
  color: white;
  margin-left: -10px;
}